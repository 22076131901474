// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-in-cosa-crediamo-js": () => import("./../../../src/pages/in-cosa-crediamo.js" /* webpackChunkName: "component---src-pages-in-cosa-crediamo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lavora-con-noi-js": () => import("./../../../src/pages/lavora-con-noi.js" /* webpackChunkName: "component---src-pages-lavora-con-noi-js" */),
  "component---src-pages-marchi-js": () => import("./../../../src/pages/marchi.js" /* webpackChunkName: "component---src-pages-marchi-js" */),
  "component---src-pages-modello-organizzativo-codice-etico-js": () => import("./../../../src/pages/modello-organizzativo-codice-etico.js" /* webpackChunkName: "component---src-pages-modello-organizzativo-codice-etico-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-single-js": () => import("./../../../src/pages/news-single.js" /* webpackChunkName: "component---src-pages-news-single-js" */),
  "component---src-pages-pam-a-casa-js": () => import("./../../../src/pages/pam-a-casa.js" /* webpackChunkName: "component---src-pages-pam-a-casa-js" */),
  "component---src-pages-pam-city-js": () => import("./../../../src/pages/pam-city.js" /* webpackChunkName: "component---src-pages-pam-city-js" */),
  "component---src-pages-pam-franchising-js": () => import("./../../../src/pages/pam-franchising.js" /* webpackChunkName: "component---src-pages-pam-franchising-js" */),
  "component---src-pages-pam-ins-js": () => import("./../../../src/pages/pam-ins.js" /* webpackChunkName: "component---src-pages-pam-ins-js" */),
  "component---src-pages-pam-local-js": () => import("./../../../src/pages/pam-local.js" /* webpackChunkName: "component---src-pages-pam-local-js" */),
  "component---src-pages-pam-panorama-js": () => import("./../../../src/pages/pam-panorama.js" /* webpackChunkName: "component---src-pages-pam-panorama-js" */),
  "component---src-pages-press-comunicati-js": () => import("./../../../src/pages/press-comunicati.js" /* webpackChunkName: "component---src-pages-press-comunicati-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-press-media-js": () => import("./../../../src/pages/press-media.js" /* webpackChunkName: "component---src-pages-press-media-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-whistleblowing-js": () => import("./../../../src/pages/whistleblowing.js" /* webpackChunkName: "component---src-pages-whistleblowing-js" */)
}

